/* Ensures the body and html take the full height */
html, body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #00B0F1, #000CB3) !important; /* Diagonal gradient */
  color: #e8e5ea;
}


/* Ensure #root takes full height */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Ensure the main content area takes up the available space */
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

/* Footer stays at the bottom */
footer {
  margin-top: auto;
  padding: 10px 0; /* Padding to ensure some space around the footer */
  background-color: #0e0911; /* Footer background color */
  text-align: center; /* Center the footer content */
}
