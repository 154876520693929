/* Footer.css */
.footer-link {
    color: #e8e5ea;  /* Default link color */
    text-decoration: none;
    margin: 0 10px;  /* Add spacing between links */
    font-size: 14px;
    font-weight: 500;  /* Optional: Make the links a bit bolder */
    transition: color 0.3s ease;  /* Smooth transition for hover effect */
}

.footer-link:hover {
    color: #000CB3;  /* Change color on hover */
}

/* footer.css */
footer {
    background-color: #0e0911;
    color: #e8e5ea;
    text-align: center;
    padding: 10px 0;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  